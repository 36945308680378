'use client'

import { useCallback, useEffect } from 'react'
import { log, parsedStackFrame } from '@astronautsid/wpe-utils/helpers/datadog'

import ErrorScreen from 'components/ErrorScreen/ErrorScreen'

const ErrorBoundary = ({ error }: { error: Error & { digest?: string } }) => {
  const handleLogs = useCallback(async () => {
    const parsedErrorStack = await parsedStackFrame(error)

    log.error(`${error.name}: ${error.message}`, {
      customInfo: {
        origin: 'error-boundary',
      },
      error: {
        stack: parsedErrorStack,
      },
    })
  }, [error])

  useEffect(() => {
    const isChunkLoadError = /loading chunk .+ failed/gi.test(error?.message || '')

    /**
     * skip log error from server
     */
    if (error.digest) {
      return
    }

    if (isChunkLoadError) return

    handleLogs()
  }, [error, handleLogs])

  return <ErrorScreen />
}

export default ErrorBoundary
