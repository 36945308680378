'use client'

import { type ReactNode } from 'react'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Button } from '@astronautsid/wpe-astro-ui/components/atoms/Button'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

import Image from 'components/Image'
import { ASSET_PREFIX } from 'config/constants'

type ErrorPagePropsType = {
  containerBoxProps?: BoxPropsType
  topImage?: ReactNode
  title?: ReactNode
  description?: ReactNode
  ctaButton?: ReactNode
}

const ErrorScreen = ({
  containerBoxProps,
  topImage,
  title,
  description,
  ctaButton,
}: ErrorPagePropsType) => {
  const theme = useTheme()

  const renderTopImage = () => {
    if (topImage !== undefined) {
      return topImage
    }

    return (
      <Box sx={{ aspectRatio: 5 / 3 }}>
        <Image
          src={`${ASSET_PREFIX}/img/error-page-default.svg`}
          style={{
            backgroundColor: theme.palette.bgColor.light,
          }}
          alt="error-page-illustration"
          fill
        />
      </Box>
    )
  }

  const renderTitle = () => {
    if (title !== undefined) {
      return title
    }

    return (
      <Typography component="h1" variant="headline-large" textAlign="center" mt="24px">
        Lagi ada masalah server
      </Typography>
    )
  }

  const renderDescription = () => {
    if (description !== undefined) {
      return description
    }

    return (
      <Typography
        component="p"
        variant="body-small"
        color="textColor.secondaryDark"
        textAlign="center"
        mt="4px"
      >
        Astro perbaiki secepat mungkin, ya. Kamu bisa langsung refresh atau coba lagi nanti.
      </Typography>
    )
  }

  const renderCtA = () => {
    if (ctaButton !== undefined) {
      return ctaButton
    }

    return (
      <Box mt="16px">
        <Box width="240px" maxWidth="240px" textAlign="center" margin="0 auto">
          <Button onClick={() => window && window.location.reload()} fullWidth>
            Refresh
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      px="30px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="100vh"
      {...containerBoxProps}
    >
      {renderTopImage()}
      {renderTitle()}
      {renderDescription()}
      {renderCtA()}
    </Box>
  )
}

export default ErrorScreen
